import React from "react"

import Layout from "../components/layout"
import {Button} from "../components/button"
import FeaturedBanner from "../components/featuredBanner"

const InfographicPage = () => (
    <Layout>
      <section className="infographic-page">
        <div className="infographic-preview">
            <h1>Thank you!</h1>
            <p className="paragraph-text">Download your infographic below.</p>
            <Button buttonText="Download Infographic File" />
        </div>
        <FeaturedBanner />
      </section>
    </Layout>
)

export default InfographicPage
